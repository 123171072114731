<template>
  <b-card>
    <div>
      <b-row>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-success"
          class="btn-icon rounded-circle ml-1"
          @click="showSearch = false"
          v-show="showSearch"
        >
          <feather-icon icon="SearchIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-warning"
          class="btn-icon rounded-circle ml-1"
          @click="showSearch = true"
          v-show="!showSearch"
        >
          <feather-icon icon="XIcon" />
        </b-button>

        <b-col v-show="!showSearch">
          <b-form-input
            placeholder="Pesquisar"
            v-model="search"
            debounce="800"
            size="sm"
            type="search"
          />
        </b-col>
      </b-row>
    </div>
    <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
    <b-table-simple
      striped
      stacked="md"
      class="mt-2 responsive text-center"
      id="tableResponsibles"
      :item="fetchResponsibles"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <b-thead>
        <b-tr>
          <b-th>Nome</b-th>
          <b-th>CPF</b-th>
          <b-th>E-mail</b-th>
          <b-th>Celular</b-th>
          <b-th>Perfil</b-th>
          <b-th>Ações</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr :key="indextr" v-for="(tr, indextr) in responsibles">
          <b-td>{{tr.name}}</b-td>
          <b-td>{{tr.cpf_cnpj}}</b-td>
          <b-td>{{tr.email}}</b-td>
          <b-td v-if="!tr.cell_phone"> - </b-td>
          <b-td v-else>{{tr.cell_phone}}</b-td>
          <b-td>
            <b-button
              variant="gradient-primary"
              size="sm"
              :to="{ path: '/responsavel/' + tr.id + '/perfil' }"
            >
              <feather-icon icon="EyeIcon" />
               Visualizar
            </b-button>
          </b-td>
          <b-td>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                v-b-modal.modal-delete
                @click="showDetail(tr.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-pagination
      @change="handlePageChange"
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="totalRows"
      aria-controls="tableResponsibles"
      class="mt-1"
    />

     <!-- Modal Delete -->

     <b-modal
        id="modal-delete"
        ok-variant="danger"
        modal-class="modal-danger"
        ref="modal-delete"
        centered
        title="Deletar"
      >
        <b-card-text>
          Você está preste a deletar este responsável do sistema:
          <hr>
          <p><strong>{{ responsiveModel ? responsiveModel.name : '' }}</strong></p>
          <hr>
          Por favor, confirme a exclusão antes de prosseguir.
          <b-form-checkbox
            id="checkbox-1"
            @change="newDeleteStatus"
            v-model="DeleteStatus"
            name="checkbox-1"
            value="accepted"
            unchecked-value="null"
          >
            Concordo em excluir este responsável
          </b-form-checkbox>
        </b-card-text>
        <template #modal-footer>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-2"
              variant="outline-danger"
              block
              :disabled="DeleteStatus == null"
              @click="deleteResponsible(responsiveModel.id)"
            >
              Deletar
            </b-button>
          </b-col>
        </template>
      </b-modal>

    </b-overlay>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSearch: true,
      isLoading: true,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      DeleteStatus: null,
      responsiveModel: null,

    };
  },
  computed: {
    ...mapState("responsibles", ["responsibles", "pagination"]),
  },
  methods: {
    deleteResponsible(id) { 
      this.isLoading = true;
      let data = id; 
      this.$store.dispatch("responsibles/delete", data)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })
        this.isLoading = false;
        this.DeleteStatus = null;
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.fetchResponsibles();
        this.$refs['modal-delete'].hide()
      });
    },
    fetchResponsibles(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("responsibles/fetchResponsibles", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showDetail(id){
      this.responsiveModel = this.$store.getters['responsibles/getResponsiblesById'](id)
    },
    newDeleteStatus(value){
      this.DeleteStatus = value
    },

    
    handlePageChange(value) {
      this.fetchResponsibles(true, value);
    },

  },
  created() {
    this.fetchResponsibles();
  },
  watch: {
     search(val) {
      this.currentPage = 1;
      this.fetchResponsibles(true, 1);
    },

  },
};
</script>

<style>
</style>
